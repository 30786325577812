import React from 'react';
import logo from './assets/img/logo.png';

function App() {
  return (
    <div>
      <img src={logo} alt="Warp Aero" />
    </div>
  );
}

export default App;
